.Wrapper {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  overflow: clip;
  overflow-clip-margin: 8px;
}

.Party_Panda_Container {
  max-width: 1151px;
  max-height: 479px;
  min-width: 980px;
  overflow: clip;
}

@media screen and (max-width: 767px) {
  .Party_Panda_Container {
    min-width: 1060px;
  }
}

.Party_Panda {
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

/*** Panda ***/
.Head {
  animation-name: Head;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Head {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  50% {
    transform: translateX(10px) translateY(5px);
  }
  100% {
    transform: translateX(20px) translateY(0px);
  }
}

.Forearm_Right {
  animation-name: Forearm_Right;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Forearm_Right {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(10px) rotate(30deg);
  }
}

.Hand_Right {
  animation-name: Hand_Right;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Hand_Right {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateX(2px) translateY(10.7px) rotate(-14deg);
  }
}

.Forearm_Left {
  animation-name: Forearm_Left;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Forearm_Left {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  100% {
    transform: rotate(30deg) translateY(-10px);
  }
}

.Hand_Left {
  animation-name: Hand_Left;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Hand_Left {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateX(3px) translateY(-10.7px) rotate(-14deg);
  }
}

.Belly {
  animation-name: Belly;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Belly {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.Belly_Outer {
  animation-name: Belly_Outer;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Belly_Outer {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(8px);
  }
}

.Belly_Inner {
  animation-name: Belly_Inner;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Belly_Inner {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}

.Belly_Button {
  animation-name: Belly_Button;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Belly_Button {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}

.Neck_Shadow {
  animation-name: Neck_Shadow;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Neck_Shadow {
  0% {
    transform: translateY(2px) translateX(-4px) rotate(0deg);
  }
  50% {
    transform: translateY(6px) translateX(5px) rotate(3deg);
  }
  100% {
    transform: translateY(2px) translateX(8px) rotate(13deg);
  }
}

.Leg_Left_Shadow {
  animation-name: Leg_Left_Shadow;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Leg_Left_Shadow {
  0% {
    transform: translateY(0px) translateX(0px);
    width: 26.45px;
  }
  100% {
    transform: translateY(5px) translateX(-3.2px);
    width: 31.5px;
  }
}

.Leg_Right_Shadow {
  animation-name: Leg_Right_Shadow;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Leg_Right_Shadow {
  0% {
    transform: translateX(0px) translateY(0px);
    width: 10.29px;
  }
  100% {
    transform: translateX(3px) translateY(5px);
    width: 13.5px;
  }
}

/*** Lights ***/

.Pink_Light_Beam {
  animation-name: Pink_Light_Beam;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Pink_Light_Beam {
  0% {
    transform: rotate(0deg) translateX(0px);
  }
  25% {
    transform: rotate(-6deg) translateX(24px);
  }
  50% {
    transform: rotate(0deg) translateX(0px);
  }
  75% {
    transform: rotate(6deg) translateX(-24px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
}

.Pink_Light_Spot {
  animation-name: Pink_Light_Spot;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Pink_Light_Spot {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(50px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}

.Yellow_Light_Beam {
  animation-name: Yellow_Light_Beam;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Yellow_Light_Beam {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  25% {
    transform: translateX(-24px) rotate(6deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(24px) rotate(-6deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.Yellow_Light_Spot {
  animation-name: Yellow_Light_Spot;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Yellow_Light_Spot {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  25% {
    transform: translateX(-50px) translateY(-10px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

.Aqua_Light_Beam {
  animation-name: Aqua_Light_Beam;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Aqua_Light_Beam {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  25% {
    transform: translateX(-24px) rotate(6deg);
  }
  50% {
    transform: translateX(0px) rotate(0deg);
  }
  75% {
    transform: translateX(24px) rotate(-6deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

.Aqua_Light_Spot {
  animation-name: Aqua_Light_Spot;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Aqua_Light_Spot {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  25% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(50px) translateY(-10px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

/*** Messages ***/

.Praise_Message_Center {
  animation-name: Praise_Message_Center;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Center {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}

.Message_Center_Shadow {
  animation-name: Message_Center_Shadow;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Message_Center_Shadow {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  100% {
    transform: translateX(-2px) translateY(2px);
  }
}

.Praise_Message_Icon_Center {
  animation-name: Praise_Message_Icon_Center;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Icon_Center {
  0% {
    transform: rotate(0deg) translateY(7px);
  }
  100% {
    transform: rotate(16deg) translateY(-7px);
  }
}

.Praise_Message_Left {
  animation-name: Praise_Message_Left;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Left {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(6deg);
  }
}

.Message_Left_Shadow {
  animation-name: Message_Left_Shadow;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Message_Left_Shadow {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  100% {
    transform: translateX(2px) translateY(-2px);
  }
}

.Praise_Message_Icon_Left {
  animation-name: Praise_Message_Icon_Left;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Icon_Left {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-16deg);
  }
}

.Praise_Message_Right {
  animation-name: Praise_Message_Right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(4deg);
  }
}

.Message_Right_Shadow {
  animation-name: Message_Right_Shadow;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Message_Right_Shadow {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  100% {
    transform: translateX(2px) translateY(-2px);
  }
}

.Praise_Message_Icon_Right {
  animation-name: Praise_Message_Icon_Right;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Icon_Right {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  100% {
    transform: rotate(28deg) translateY(5px);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Praise_Message_Right,
  .Praise_Message_Icon_Right {
    translate: -120px;
  }
  .Praise_Message_Left,
  .Praise_Message_Icon_Left {
    translate: 120px;
  }
}

@media screen and (max-width: 767px) {
  .Praise_Message_Right,
  .Praise_Message_Left,
  .Message_Right_Shadow,
  .Message_Left_Shadow,
  .Praise_Message_Icon_Right,
  .Praise_Message_Icon_Left {
    display: none;
  }
}

/*** Confetti **/

.confettis {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1151px;
  max-height: 479px;
  overflow: clip;
  pointer-events: none;
  user-select: none;
  cursor: default;
}

.confetti {
  position: absolute;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
  pointer-events: none;
  margin-top: -15px;
  width: 10px;
  height: 5px;
  border-radius: 100px;
  z-index: 1;
  background: #ffce1b;
  animation-name: confetti-fall, confetti-shake;
  animation-duration: 3s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}

@keyframes confetti-fall {
  0% {
    top: -10%;
    opacity: 1;
  }
  100% {
    top: 110%;
    opacity: 0;
  }
}

@keyframes confetti-shake {
  0%,
  100% {
    transform: translateX(0) rotate(45deg);
  }
  50% {
    transform: translateX(80px) rotate(-45deg);
  }
}

.confetti:nth-of-type(3n + 1) {
  background: #2b5cfb;
}

.confetti:nth-of-type(2n + 3) {
  background: #ff4040;
}

.confetti:nth-of-type(4n + 3) {
  width: 12px;
  height: 4px;
}

.confetti:nth-of-type(5n + 3) {
  width: 8px;
  height: 6px;
}

.confetti:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}

.confetti:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}

.confetti:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}

.confetti:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}

.confetti:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}

.confetti:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}

.confetti:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}

.confetti:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}

.confetti:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}

.confetti:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}

.confetti:nth-of-type(10) {
  left: 25%;
  -webkit-animation-delay: 2s, 0s;
  animation-delay: 2s, 0s;
}

.confetti:nth-of-type(11) {
  left: 65%;
  -webkit-animation-delay: 4s, 2.5s;
  animation-delay: 4s, 2.5s;
}

.Message_Center_Avatar_Photo {
  transform: rotate(-4.34deg);
}

.Message_Left_Avatar_Photo {
  transform: rotate(-8.66deg);
}

.Message_Right_Avatar_Photo {
  transform: rotate(13.56deg);
}
