.Praise_Feature_Container {
  max-width: 507px;
  max-height: 424px;
}

.Praise_Feature {
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

/** Praise Creation **/

.Praise_Message_Creation {
  animation-name: Praise_Message_Creation;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Message_Creation {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  5% {
    opacity: 1;
  }
  48% {
    opacity: 1;
    transform: translateX(0px);
  }
  55% {
    opacity: 0;
  }
  58% {
    transform: translateX(-754px);
  }
  100% {
    transform: translateX(-754px);
  }
}

.Typing_Overlay_Top {
  animation-name: Typing_Overlay_Top;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Typing_Overlay_Top {
  0% {
    transform: translateX(0px);
  }
  5% {
    transform: translateX(0px);
  }
  20.5% {
    transform: translateX(377px);
  }
  100% {
    transform: translateX(377px);
  }
}
.Typing_Overlay_Bottom {
  animation-name: Typing_Overlay_Bottom;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Typing_Overlay_Bottom {
  0% {
    transform: translateX(0px);
  }
  20.7% {
    transform: translateX(0px);
  }
  36.4% {
    transform: translateX(343px);
  }
  100% {
    transform: translateX(343px);
  }
}

.Typing_Line_Top {
  animation-name: Typing_Line_Top;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Typing_Line_Top {
  0% {
    transform: translateX(0px);
  }
  5% {
    transform: translateX(0px);
  }
  20.5% {
    transform: translateX(379px);
    opacity: 1;
  }
  21% {
    opacity: 0;
  }
  100% {
    transform: translateX(379px);
    opacity: 0;
  }
}
.Typing_Line_Bottom {
  animation-name: Typing_Line_Bottom;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Typing_Line_Bottom {
  0% {
    opacity: 0;
  }
  20.7% {
    opacity: 0;
    transform: translateX(0px);
  }
  21% {
    opacity: 1;
  }
  35% {
    transform: translateX(313px);
  }
  34.5% {
    opacity: 1;
  }
  36% {
    opacity: 0;
  }
  100% {
    transform: translateX(313px);
    opacity: 0;
  }
}

.Praise_User_Fill {
  animation-name: Praise_User_Fill;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_User_Fill {
  0% {
    opacity: 0;
  }
  10.3% {
    opacity: 0;
  }
  13.3% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.Praiser_User_Highlight {
  animation-name: Praise_User_Highlight;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praiser_User_Highlight {
  0% {
    opacity: 0;
  }
  10.3% {
    opacity: 0;
  }
  13.3% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.Star_Icon {
  animation-name: Star_Icon;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Star_Icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  36% {
    opacity: 0;
    transform: scale(0);
  }
  38% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.Send_Button {
  animation-name: Send_Button;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Send_Button {
  0% {
    transform: scale(1);
  }
  42.5% {
    transform: scale(1);
  }
  43.5% {
    transform: scale(1.2);
  }
  45.5% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.Send_Button_Icon {
  animation-name: Send_Button_Icon;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Send_Button_Icon {
  0% {
    opacity: 1;
  }
  42.5% {
    opacity: 1;
  }
  44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Send_Button_Icon_Flying {
  animation-name: Send_Button_Icon_Flying;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Send_Button_Icon_Flying {
  0% {
    opacity: 0;
    transform: translateX(0px) translateY(0px) rotate(0deg);
  }
  43% {
    opacity: 0;
  }
  44.3% {
    opacity: 1;
  }
  45.5% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1);
  }
  47.5% {
    transform: scale(3);
  }
  50.5% {
    transform: translateX(-65px) translateY(-150px) rotate(20deg) scale(3);
  }
  55.5% {
    opacity: 1;
  }
  56.5% {
    transform: translateX(-130px) translateY(20px) rotate(-40deg);
  }
  59% {
    opacity: 0;
    transform: translateX(-130px) translateY(20px) rotate(-40deg) scale(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-130px) translateY(20px) rotate(-40deg) scale(0);
  }
}

/** Slack Output **/

.Praise_Slack_Output {
  animation-name: Praise_Slack_Output;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Slack_Output {
  0% {
    transform: translateX(0px);
  }
  51% {
    transform: translateX(0px);
  }
  54% {
    opacity: 0;
  }
  57% {
    transform: translateX(-754px);
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-754px);
  }
}

.Firework_Purple {
  animation-name: Firework_Purple;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Firework_Purple {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  56.5% {
    opacity: 0;
  }
  57% {
    transform: scale(0);
  }
  58% {
    opacity: 1;
  }
  62% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.Firework_Pink {
  animation-name: Firework_Pink;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Firework_Pink {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  56% {
    opacity: 0;
  }
  57% {
    transform: scale(0);
  }
  57.5% {
    opacity: 1;
  }
  63% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.Praise_Output {
  animation-name: Praise_Output;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Praise_Output {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  57% {
    opacity: 0;
    transform: translateY(0px);
  }
  62% {
    opacity: 1;
    transform: translateY(-88px);
  }
  100% {
    opacity: 1;
    transform: translateY(-88px);
  }
}

.Flame_Reaction {
  animation-name: Flame_Reaction;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Flame_Reaction {
  0% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }
  77% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }
  80% {
    opacity: 1;
    transform: translateY(-40px) scale(1.4);
  }
  82% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
}

.Smiley_Reaction {
  animation-name: Smiley_Reaction;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Smiley_Reaction {
  0% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }
  81% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }
  84% {
    opacity: 1;
    transform: translateY(-40px) scale(1.4);
  }
  86% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
}

.Plus_One_Reaction {
  animation-name: Plus_One_Reaction;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Plus_One_Reaction {
  0% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }
  67% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }
  69% {
    opacity: 1;
    transform: translateY(-40px) scale(1.4);
  }
  71% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(-40px) scale(1);
  }
}

.Plus_One_Reaction_Count_One_Static {
  animation-name: Plus_One_Reaction_Count_One_Static;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Plus_One_Reaction_Count_One_Static {
  0% {
    opacity: 1;
  }
  70.7% {
    opacity: 1;
  }
  71.5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Pus_One_Reaction_Count_One {
  animation-name: Pus_One_Reaction_Count_One;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Pus_One_Reaction_Count_One {
  0% {
    opacity: 0;
  }
  71% {
    opacity: 0;
  }
  72% {
    transform: translateY(0px);
    opacity: 1;
  }
  73.3% {
    transform: translateY(7px);
    opacity: 0;
  }
  100% {
    transform: translateY(7px);
    opacity: 0;
  }
}

.Pus_One_Reaction_Count_Two {
  animation-name: Pus_One_Reaction_Count_Two;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Pus_One_Reaction_Count_Two {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  72% {
    transform: translateY(0px);
    opacity: 0;
  }
  73.3% {
    opacity: 1;
  }
  75% {
    transform: translateY(14px);
    opacity: 0;
  }
  100% {
    transform: translateY(14px);
    opacity: 0;
  }
}

.Pus_One_Reaction_Count_Three {
  animation-name: Pus_One_Reaction_Count_Three;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Pus_One_Reaction_Count_Three {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  73.5% {
    transform: translateY(0px);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  76.5% {
    transform: translateY(14px);
    opacity: 0;
  }
  100% {
    transform: translateY(14px);
    opacity: 0;
  }
}

.Pus_One_Reaction_Count_Four {
  animation-name: Pus_One_Reaction_Count_Four;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Pus_One_Reaction_Count_Four {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  75% {
    transform: translateY(0px);
    opacity: 0;
  }
  76% {
    transform: translateY(7.66px);
    opacity: 1;
  }
  100% {
    transform: translateY(7.66px);
    opacity: 1;
  }
}
