.Stat_Feature_Container {
  max-width: 507px;
  max-height: 484px;
}

.Stat_Feature {
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

/** Stat Messages **/

.Stat_Top {
  animation-name: Stat_Top;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Top {
  0% {
    opacity: 0;
    transform: scale(0) rotate(-6deg);
  }
  4.75% {
    opacity: 1;
  }
  6.65% {
    transform: scale(1.05) rotate(3.75deg);
  }
  7.6% {
    transform: scale(1) rotate(4.25deg);
  }
  9.52% {
    transform: scale(1.03) rotate(6deg);
  }
  10.48% {
    transform: scale(1.04) rotate(5.65deg);
  }
  14.29% {
    transform: scale(1) rotate(-1.78deg);
  }
  17.14% {
    transform: scale(1.0125) rotate(-4deg);
  }
  20% {
    transform: scale(1.025) rotate(-3.13deg);
  }
  26.67% {
    transform: scale(1.00625) rotate(5deg);
  }
  28.57% {
    transform: scale(1) rotate(4.18deg);
  }
  35.71% {
    transform: scale(1) rotate(-4deg);
  }
  46.67% {
    transform: scale(1) rotate(4deg);
  }
  57.14% {
    transform: scale(1) rotate(-3deg);
  }
  64.76% {
    opacity: 1;
    transform: scale(1) rotate(3deg) translateX(0px) translateY(0px);
  }
  69.81% {
    opacity: 0;
    transform: scale(0) rotate(180deg) translateX(50px) translateY(-930px);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(180deg) translateX(50px) translateY(-930px);
  }
}

.Stat_Bottom {
  animation-name: Stat_Bottom;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Bottom {
  0% {
    opacity: 0;
    transform: scale(0) rotate(-4deg);
  }
  14.29% {
    opacity: 0;
    transform: scale(0) rotate(-4deg);
  }
  19.05% {
    opacity: 1;
  }
  20.95% {
    transform: scale(1.05) rotate(4deg);
  }
  21.9% {
    transform: scale(1) rotate(3.55deg);
  }
  24.76% {
    transform: scale(1.04) rotate(-2.15deg);
  }
  26.67% {
    transform: scale(1.02) rotate(-4deg);
  }
  28.57% {
    transform: scale(1) rotate(-2.97deg);
  }
  34.29% {
    transform: scale(1.025) rotate(4deg);
  }
  42.86% {
    transform: scale(1) rotate(-3deg);
  }
  52.95% {
    transform: scale(1) rotate(3deg);
  }
  65.71% {
    opacity: 1;
    transform: scale(1) rotate(-3deg) translateX(0px) translateY(0px);
  }
  70.95% {
    opacity: 0;
    transform: scale(0) rotate(180deg) translateX(-50px) translateY(930px);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(180deg) translateX(-50px) translateY(930px);
  }
}

/** Stat Icon Burst **/

.Stat_Top_Icon_BurstA {
  animation-name: Stat_Top_Icon_BurstA;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Top_Icon_BurstA {
  0% {
    opacity: 0;
  }
  6.67% {
    opacity: 0;
    transform: scale(0.2) translateX(0px) translateY(0px);
  }
  12.95% {
    opacity: 1;
  }
  17.71% {
    opacity: 0;
    transform: scale(1) translateX(-17px) translateY(-20px);
  }
  100% {
    opacity: 0;
  }
}

.Stat_Top_Icon_BurstB {
  animation-name: Stat_Top_Icon_BurstB;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Top_Icon_BurstB {
  0% {
    opacity: 0;
  }
  9.52% {
    opacity: 0;
    transform: scale(0.15) translateX(0px) translateY(0px);
  }
  17.71% {
    opacity: 1;
  }
  20.57% {
    opacity: 0;
    transform: scale(1) translateX(14px) translateY(-18px);
  }
  100% {
    opacity: 0;
  }
}

.Stat_Top_Icon_BurstC {
  animation-name: Stat_Top_Icon_BurstC;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Top_Icon_BurstC {
  0% {
    opacity: 0;
  }
  8.1% {
    opacity: 0;
    transform: scale(0.2) translateX(0px) translateY(0px);
  }
  16.19% {
    opacity: 1;
  }
  19.05% {
    opacity: 0;
    transform: scale(1) translateX(-11px) translateY(-28px);
  }
  100% {
    opacity: 0;
  }
}

.Stat_Top_Icon_BurstD {
  animation-name: Stat_Top_Icon_BurstD;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Top_Icon_BurstD {
  0% {
    opacity: 0;
  }
  5.71% {
    opacity: 0;
    transform: scale(0.25) translateX(0px) translateY(0px);
  }
  15.24% {
    opacity: 1;
  }
  18.1% {
    opacity: 0;
    transform: scale(1) translateX(8px) translateY(-26px);
  }
  100% {
    opacity: 0;
  }
}

.Stat_Top_Icon_BurstE {
  animation-name: Stat_Top_Icon_BurstE;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Top_Icon_BurstE {
  0% {
    opacity: 0;
  }
  5.71% {
    opacity: 0;
    transform: scale(0.425) translateX(0px) translateY(0px);
  }
  14% {
    opacity: 1;
  }
  17.14% {
    opacity: 0;
    transform: scale(1) translateX(3px) translateY(-18px);
  }
  100% {
    opacity: 0;
  }
}

.Stat_Bottom_Icon_BurstB {
  animation-name: Stat_Bottom_Icon_BurstB;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Bottom_Icon_BurstB {
  0% {
    opacity: 0;
  }
  22.86% {
    opacity: 0;
    transform: scale(0.2) translateX(0px) translateY(0px);
  }
  31.14% {
    opacity: 1;
  }
  34% {
    opacity: 0;
    transform: scale(1) translateX(22px) translateY(-26px);
  }
  100% {
    opacity: 0;
  }
}
.Stat_Bottom_Icon_BurstC {
  animation-name: Stat_Bottom_Icon_BurstC;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Bottom_Icon_BurstC {
  0% {
    opacity: 0;
  }
  21.9% {
    opacity: 0;
    transform: scale(0.3) translateX(0px) translateY(0px);
  }
  28.1% {
    opacity: 1;
  }
  32.86% {
    opacity: 0;
    transform: scale(1) translateX(-19px) translateY(-15px);
  }
  100% {
    opacity: 0;
  }
}
.Stat_Bottom_Icon_BurstD {
  animation-name: Stat_Bottom_Icon_BurstD;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Bottom_Icon_BurstD {
  0% {
    opacity: 0;
  }
  20.95% {
    opacity: 0;
    transform: scale(0.15) translateX(0px) translateY(0px);
  }
  27.14% {
    opacity: 1;
  }
  31.9% {
    opacity: 0;
    transform: scale(1) translateX(14px) translateY(-30px);
  }
  100% {
    opacity: 0;
  }
}
.Stat_Bottom_Icon_BurstE {
  animation-name: Stat_Bottom_Icon_BurstE;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Stat_Bottom_Icon_BurstE {
  0% {
    opacity: 0;
  }
  19.52% {
    opacity: 0;
    transform: scale(0.2) translateX(0px) translateY(0px);
  }
  27.14% {
    opacity: 1;
  }

  30.95% {
    opacity: 0;
    transform: scale(1) translateX(4px) translateY(-29px);
  }
  100% {
    opacity: 0;
  }
}

/** Export **/

.Export_Label {
  animation-name: Export_Label;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Export_Label {
  0% {
    opacity: 0;
  }
  47.62% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
  }
  52.38% {
    opacity: 1;
    transform: scale(1);
  }
  71.43% {
    opacity: 1;
    transform: translateY(0px);
  }
  73.33% {
    opacity: 0;
    transform: translateY(23px);
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}

.Export_Button {
  animation-name: Export_Button;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Export_Button {
  0% {
    opacity: 0;
  }
  47.62% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  52.38% {
    opacity: 1;
    transform: scale(1);
  }
  68.57% {
    transform: scale(1);
  }
  70.48% {
    transform: scale(1.1);
  }
  73.33% {
    transform: scale(1);
  }
  95.24% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Checkmark_Icon {
  animation-name: Checkmark_Icon;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Checkmark_Icon {
  0% {
    opacity: 0;
  }
  72.86% {
    opacity: 0;
    transform: scale(0);
  }
  74.29% {
    opacity: 1;
  }
  76.19% {
    transform: scale(1.05);
  }
  77.14% {
    transform: scale(1);
  }
  95.24% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Checkmark_BG {
  animation-name: Checkmark_BG;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Checkmark_BG {
  0% {
    opacity: 0;
  }
  71.9% {
    opacity: 0;
    transform: scale(0);
  }
  75.24% {
    opacity: 1;
    transform: scale(1);
  }
  95.24% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/** Stars **/

.Star_Large {
  animation-name: Star_Large;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Star_Large {
  0% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  73% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  76% {
    opacity: 0.2;
  }
  83% {
    opacity: 0.8;
    transform: scale(1.3) rotate(-60deg);
  }
  93% {
    opacity: 0.3;
    transform: scale(1) rotate(-120deg);
  }
  100% {
    opacity: 0;
    transform: scale(1.3) rotate(-162deg);
  }
}

.Star_Medium {
  animation-name: Star_Medium;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Star_Medium {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  77% {
    opacity: 1;
  }
  82% {
    opacity: 0.4;
    transform: scale(1.3) rotate(80deg);
  }
  92% {
    opacity: 0.8;
    transform: scale(1) rotate(160deg);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(226deg);
  }
}

.Star_Small {
  animation-name: Star_Small;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Star_Small {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  74% {
    opacity: 0.4;
  }
  80% {
    transform: scale(1.4) rotate(-70deg);
  }
  90% {
    opacity: 0.8;
    transform: scale(1) rotate(-140deg);
  }
  100% {
    opacity: 0;
    transform: scale(1.3) rotate(-210deg);
  }
}
