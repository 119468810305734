.Drawing_Feature_Container {
  max-width: 507px;
  max-height: 401px;
}

.Drawing_Feature {
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

/** Spinning Wheel **/

.Wheel {
  animation-name: Wheel;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Wheel {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }

  47.5% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 0;
  }
}

.Wheel_Slivers {
  animation-name: Wheel_Slivers;
  animation-duration: 10.5s;
  animation-timing-function: ease-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Wheel_Slivers {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(654deg);
  }
  100% {
    transform: rotate(654deg);
  }
}

.Aqua_Sliver_Winner {
  animation-name: Sliver_Winner;
  animation-duration: 10.5s;
  animation-timing-function: step-start;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Sliver_Winner {
  5% {
    fill: #01f2d5;
  }
  35% {
    fill: #01f2d5;
  }
  37% {
    fill: #eb00df;
  }
  39% {
    fill: #ffce1b;
  }
  41% {
    fill: #9441ff;
  }
  43% {
    fill: #01f2d5;
  }
  45% {
    fill: #eb00df;
  }
  47.5% {
    fill: #ffce1b;
  }
  100% {
    fill: #01f2d5;
  }
}

.Aqua_Qmark_Winner {
  animation-name: Qmark_Winner;
  animation-duration: 10.5s;
  animation-timing-function: ease-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Qmark_Winner {
  5% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.4;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Wheel_Winner {
  animation-name: Wheel_Winner;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Wheel_Winner {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  47.5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Wheel_Winner_Avatar_Highlight {
  animation-name: Wheel_Winner_Avatar_Highlight;
  animation-duration: 10.5s;
  animation-timing-function: step-start;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Wheel_Winner_Avatar_Highlight {
  5% {
    fill: #01f2d5;
  }
  35% {
    fill: #01f2d5;
  }
  37% {
    fill: #eb00df;
  }
  39% {
    fill: #ffce1b;
  }
  41% {
    fill: #9441ff;
  }
  43% {
    fill: #01f2d5;
  }
  45% {
    fill: #eb00df;
  }
  47.5% {
    fill: #ffce1b;
  }
  100% {
    fill: #01f2d5;
  }
}

/** Winner Announcement **/

.Sign {
  animation-name: Sign;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Sign {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  50% {
    opacity: 0;
    transform: translateY(200px);
  }
  53% {
    opacity: 1;
    transform: translateY(0px);
  }
  95% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
}

.Sign_Winner_Avatar .Winner_Avatar {
  filter: drop-shadow(-3px 4px 0px rgb(0 0 0 / 1));
}

.Bulb_One {
  animation-name: Bulb_One;
  animation-duration: 1.5s;
  animation-timing-function: step-start;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Bulb_One {
  0% {
    fill: #01f2d5;
    stroke: #09d1b9;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #01f2d5);
  }
  33% {
    fill: #eb00df;
    stroke: #ab01a2;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #eb00df);
  }
  66% {
    fill: #ffce1b;
    stroke: #c99e03;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #ffce1b);
  }
  100% {
    fill: #01f2d5;
    stroke: #09d1b9;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #01f2d5);
  }
}

.Bulb_Two {
  animation-name: Bulb_Two;
  animation-duration: 1.5s;
  animation-timing-function: step-start;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Bulb_Two {
  0% {
    fill: #eb00df;
    stroke: #ab01a2;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #eb00df);
  }
  33% {
    fill: #ffce1b;
    stroke: #c99e03;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #ffce1b);
  }
  66% {
    fill: #01f2d5;
    stroke: #09d1b9;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #01f2d5);
  }
  100% {
    fill: #eb00df;
    stroke: #ab01a2;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #eb00df);
  }
}

.Bulb_Three {
  animation-name: Bulb_Three;
  animation-duration: 1.5s;
  animation-timing-function: step-start;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Bulb_Three {
  0% {
    fill: #ffce1b;
    stroke: #c99e03;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #ffce1b);
  }
  33% {
    fill: #01f2d5;
    stroke: #09d1b9;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #01f2d5);
  }
  66% {
    fill: #eb00df;
    stroke: #ab01a2;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #eb00df);
  }
  100% {
    fill: #ffce1b;
    stroke: #c99e03;
    stroke-width: 2;
    filter: drop-shadow(0px 0px 10px #ffce1b);
  }
}

.Winner_Praise {
  animation-name: Winner_Praise;
  animation-duration: 10.5s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}
@keyframes Winner_Praise {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  60% {
    opacity: 0;
    transform: translateY(-50px);
  }
  65% {
    opacity: 1;
    transform: translateY(0px);
  }
  95% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
}
